<template>
  <v-dialog persistent v-model="registerOperatorDilog" scrollable fullscreen>
    <v-card>
      <v-card-title style="border-bottom: 1px solid #ccc" class="px-2 mb-5"
        ><v-app-bar height="50" flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h5 pl-0 pt-0">
            {{ formData.id ? "Editar operadora" : "Nova operadora" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="toogleRegisterOperatorDilog"
            class="mt-6"
            color="red"
            fab
            icon
            dark
            x-small
            absolute
            top
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar></v-card-title
      >

      <v-card-text class="pt-0" style="max-width: 1920px; margin: auto">
        <v-form ref="operatorForm" style="width: 100%">
          <v-row class="px-8 mt-4">
            <v-col class="px-5 pt-0" cols="6">
              <v-row class="px-3 mt-4">
                <v-col class="pt-0 mb-5" cols="12">
                  <v-card-title class="pl-1"> Dados da operador </v-card-title>
                  <v-card-subtitle class="pl-1">
                    Preencha os campos abaixo para configurar a sua operadora de
                    cartão de crédito
                  </v-card-subtitle>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-combobox
                    class="requiredField"
                    :rules="$store.state.formRules.required"
                    clearable
                    v-model="formData.name"
                    :items="configuredOperators"
                    label="Selecione uma operadora"
                    item-text="name"
                    item-value="name"
                  ></v-combobox>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field
                    clearable
                    class="requiredField"
                    :rules="$store.state.formRules.required"
                    v-model="formData.credentials.merchant_id"
                    label="Merchant id"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-text-field
                    clearable
                    class="requiredField"
                    :rules="$store.state.formRules.required"
                    v-model="formData.credentials.merchant_key"
                    label="Merchant key"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-select
                    class="requiredField"
                    :rules="$store.state.formRules.required"
                    label="Tipo de numeração"
                    v-model="formData.credentials.algoritmoNumeracao"
                    :items="algoritimo"
                    item-text="label"
                    item-value="val"
                  ></v-select>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-text-field
                    clearable
                    class=""
                    v-model="formData.callback_url"
                    label="Url de callback"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col class="pl-3 pt-0" cols="6">
              <v-row class="px-3 mt-4">
                <v-col class="pt-0 mb-5" cols="12">
                  <v-card-title class="pl-1">
                    Opções de parcelamento
                  </v-card-title>
                  <v-card-subtitle class="pl-1">
                    Preencha os campos abaixo para habilitar e definir suas
                    opções de parcelamento
                  </v-card-subtitle>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-select
                    class=""
                    clearable
                    v-model="formData.installments_conditions.max_installments"
                    :items="parcels.filter((p) => p.qtd > 1)"
                    label="Selecione a quantidade máxima de parcelas"
                    item-text="text"
                    item-value="qtd"
                  ></v-select>
                </v-col>
                <v-col class="pl-5 pt-0" cols="12">
                  <v-select
                    :rules="
                      formData.installments_conditions.max_installments
                        ? $store.state.formRules.required
                        : []
                    "
                    :disabled="
                      formData.installments_conditions.max_installments
                        ? false
                        : true
                    "
                    :class="
                      formData.installments_conditions.max_installments
                        ? 'requiredField'
                        : ''
                    "
                    clearable
                    v-model="
                      formData.installments_conditions
                        .max_installments_no_interest
                    "
                    :items="parcels"
                    label="Selecione a quantidade máxima de parcelas sem juros"
                    item-text="text"
                    item-value="qtd"
                  ></v-select>
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field-money
                    :class="
                      formData.installments_conditions.max_installments
                        ? 'requiredField'
                        : ''
                    "
                    v-model="
                      formData.installments_conditions.min_installment_value
                    "
                    v-bind:label="'Valor mínimo para parcelamento'"
                    v-bind:properties="{
                      rules: formData.installments_conditions.max_installments
                        ? $store.state.formRules.required
                        : [],
                      prefix: 'R$',
                      disabled: disabFieldInstallmentValue(
                        formData.installments_conditions.max_installments
                      ),
                      readonly: false,
                      outlined: false,
                      clearable: true,
                      placeholder: ' ',
                    }"
                    v-bind:options="{
                      locale: 'pt-BR',
                      length: 11,
                      precision: 2,
                      empty: null,
                    }"
                  />
                  <!-- <v-text-field
                    clearable
                    class=""
                    v-model="formData.min_installment_value"
                    label="Valor mínimo para parcelamento"
                  ></v-text-field> -->
                </v-col>
                <v-col class="pl-5 pt-0" cols="6">
                  <v-text-field
                    :rules="
                      formData.installments_conditions.max_installments
                        ? $store.state.formRules.required
                        : []
                    "
                    :class="
                      formData.installments_conditions.max_installments
                        ? 'requiredField'
                        : ''
                    "
                    :disabled="
                      formData.installments_conditions.max_installments
                        ? false
                        : true
                    "
                    prefix=" %"
                    type="number"
                    clearable
                    class=""
                    v-model="formData.installments_conditions.monthly_interest"
                    label="Taxa de juros mensal"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="px-0 mx-3"
        style="border-top: 1px solid rgb(220, 220, 220)"
      >
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="toogleRegisterOperatorDilog">
          Fechar
        </v-btn>
        <v-btn color="green" text @click="saveConfigOperator"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const restOperatorForm = {
  name: "",
  callback_url: null,
  credentials: {
    algoritmoNumeracao: "",
    merchant_id: "",
    merchant_key: "",
  },
  installments_conditions: {
    max_installments: "",
    max_installments_no_interest: "",
    min_installment_value: null,
    monthly_interest: "",
  },
};
export default {
  watch: {
    registerOperatorDilog(v) {
      // console.log('watch', v)
      if (v) {
        if (this.editConfigData) {
          this.formData = { ...this.editConfigData };
          this.formData.credentials = { ...this.editConfigData.credentials };
          this.formData.installments_conditions = {
            ...this.editConfigData.installments_conditions,
          };
        }
      } else {
        this.$refs.operatorForm.resetValidation();
        // this.$refs.operatorForm.reset();
        this.formData = { ...restOperatorForm };
      }
    },
  },
  methods: {
    disabFieldInstallmentValue(qtdParcels) {
      if (qtdParcels) {
        return false;
      } else {
        return true;
      }
    },
    saveConfigOperator() {
      if (!this.$refs.operatorForm.validate()) {
        return;
      }
      if (!this.formData.id) {
        this.formData.name = this.formData.name.name;
      }
      var obj = {
        url: this.formData.id
          ? "/api/v1/paymentCredentials/update"
          : "/api/v1/paymentCredentials/create",
        query: this.formData,
        method: "post",
      };
      //   console.log("save", obj);
      //   return;
      this.$store.dispatch("request/doRequest", obj).then((response) => {
        this.toogleRegisterOperatorDilog();
        this.getOperators();
        let opts = {
          message: `Operadora ${
            this.formData.id ? "atualizada" : "criada"
          } com sucesso`,
          type: "success",
        };
        this.$store.commit("showAlert", opts);
      });
    },
  },
  data() {
    return {
      parcels: [
        { qtd: 1, text: "(1) Uma parcela" },
        { qtd: 2, text: "(2) Duas parcelas" },
        { qtd: 3, text: "(3) Três parcelas" },
        { qtd: 4, text: "(4) Quatro parcelas" },
        { qtd: 5, text: "(5) Cinco parcelas" },
        { qtd: 6, text: "(6) Seis parcelas" },
        { qtd: 7, text: "(7) Sete parcelas" },
        { qtd: 8, text: "(8) Oito parcelas" },
        { qtd: 9, text: "(9) Nove parcelas" },
        { qtd: 10, text: "(10) Dez parcelas" },
        { qtd: 11, text: "(11) Onze parcelas" },
        { qtd: 12, text: "(12) Doze parcelas" },
      ],
      algoritimo: [
        {
          val: "A",
          label: "Tipo (A) - Poderá gerar até 9.999 transacões por dia",
        },
        {
          val: "B",
          label: "Tipo (B) - Poderá gerar até 999.999 transações por mês",
        },
        {
          val: "C",
          label: "Tipo (C) - Poderá gerar até 99.999.999 transações por ano",
        },
        {
          val: "D",
          label:
            "Tipo (D) - Poderá gerar transação com uuid (indicador único universal)",
        },
      ],
      formData: { ...restOperatorForm },
    };
  },
  props: {
    configuredOperators: Array,
    toogleRegisterOperatorDilog: Function,
    registerOperatorDilog: Boolean,
    getOperators: Function,
    editConfigData: Object,
  },
};
</script>

<style lang="scss" scoped></style>